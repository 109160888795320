/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
/*
 * Exploration: 
 * (1) Toggle the CSS properties to see how the page changes
 * (2) What does 'margin: 0 auto;' do?
 * (3) How does flex usage change with various classes?
 * (4) How does 'width: max-content' work?
 */

.rules_page {
  margin: 3rem 0 5rem 0;
}

.rules_page h5 {
  text-align: center;
}

.rules_page h5,
.rules_page li {
  margin: 1rem;
}

.title_group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title_group > h6 {
  margin: 0;
  text-align: center;
}

.title_sandwich {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: var(--size-06);
  color: var(--swatch-04);
  font-weight: var(--weight-03);

  text-align: center;
  width: max-content;
}

.title_sandwich-text {
  margin: 0rem 0.5rem;
}