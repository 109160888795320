/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/

.copied-message {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    
    animation: fadeIn 4s;
  }
  
  .copied-message > .error-message {
    margin: 0rem;
    border-radius: 0.75rem 0.75rem 0 0;
  
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0.06125rem solid var(--swatch-11);
    
    box-shadow: 0 0.5rem 1rem 0 rgba( 31, 38, 135, 0.37 );
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    20% { opacity: 1; }
    70% { opacity: 1; }
    100% { opacity: 0; }
  }
  