/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.search_container {
    padding-top: 4rem;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 3.25rem;
    height: calc(100vh - 7.25rem);
}

.search_nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: -0.0625rem;
    height: 3rem;
    background-color: var(--swatch-04);
    z-index: 2;

    width: 100vw;
    transform: translate(0, -3rem);
}

.search_nav-back {
    margin-left: 1rem;
}

.search_input-container {
    flex-grow: 1;
    margin: 0 1rem; /* Adjust as needed */
}

.search_input-container input {
    
    width: 100%; /* Add this line */
    height: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    border-radius: 0.75rem; /* Add this line */

    outline: none; /* Add this line */
    border: none; /* Add this line */
}

.no-events-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Adjust based on your layout */
    text-align: center;
}

.disclaimer {
    padding: 1rem 0rem;
    text-align: center;
    margin: 0;
}

.share_buttons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    margin-top: 0.5rem;
    width: 100%;
    overflow-wrap: normal;
}

.vertical_share_button {
    width: 32px;
    height: 32px;
    align-items: center;
    box-sizing: border-box;
}

.vertical_share_button:hover {
    cursor: pointer;
    background-color: var(--swatch-12);
  }
  
  .vertical_share_button.active,
  .vertical_share_button.active:hover {
    animation: brightColorToGradient 4s;
  }
  
  @keyframes brightColorToGradient {
    0% { background: var(--swatch-12); }
    20% { background: var(--swatch-06); color: var(--swatch-12); }
    70% { background: var(--swatch-06); color: var(--swatch-12); }
    100% { background: var(--swatch-12); }
  }
  

.share_button {
    display: flex;
    justify-content: center;
    background-color: var(--swatch-12);
    margin-left: 0.5rem;
    padding: 0.125rem;
    border-radius: 2rem;
    box-shadow: 0 0.5rem 1rem 0 rgba( 31, 38, 135, 0.37 );
}

.share_buttons_vertical {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    margin-top: calc(-0.5rem - 24px);
    width: 100%;
}

.card {
    padding: 1rem;
    width: calc(100% - 2rem)
}

.forum-item > .card > a {
    z-index: 4;
    position: relative;
}

.forums {
    padding: 1rem;
    margin-bottom: 2rem;
}