/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
/* SimulatedEntity.css */
.card-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-width: 400px;
  position: relative;
}

.card-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.remove-option {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #999;
}

.profile-section {
  display: flex;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.details {
  flex: 1;
}

.entity-name {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.entity-role {
  margin-top: 5px;
}

.affinity-indicator {
  background-color: #4A90E2;
  color: white;
  border-radius: 3px;
  padding: 2px 8px;
  margin-top: 8px;
  display: inline-block;
}

.compatibility-bar-container {
  height: 10px;
  background-color: #EAEAEA;
  border-radius: 5px;
  margin-top: 15px;
}

.compatibility-bar-filled {
  height: 100%;
  background: linear-gradient(to right, #4A90E2, #50E3C2);
  border-radius: 5px;
}

.description-text {
  margin-top: 15px;
}
