/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.forum-fixed_wrapper {
    border-top: 1px solid var(--swatch-04);
    height: 69vh;
    overflow-y: auto;
}

.message {
  display: flex;  
}

.read-more {
    color: var(--swatch-07);
    font-weight: var(--weight-03);
}

.replies-toggle {
  font-weight: var(--weight-03);
}

.grid {
  display: flex;

  flex-direction: column;  
}

.grid-button {
  display: flex;

  flex-direction: column;

  align-items: center;

  width: 6rem;

  height: 6rem;

  justify-content: center;

  font-size: var(--size-02);

  padding: 0.5rem;

  margin: 0.5rem;

  border: solid 1px var(--swatch-07);

  border-radius: 1rem;
}

.grid-button > h6 {
  margin: 0;

  font-weight: var(--weight-03);
}

.row {
  display: flex;
  
  justify-content: space-around;
   
}

.talkHead {
  display: flex;
  justify-content: space-between;
  margin-right: 0.75rem;
  margin-top: 1rem;
}

.forum-item {
  display: flex;
  justify-content: space-between;

  background: var(--swatch-10);
  border-radius: 1rem;

  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 0.5rem 2rem 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 0.25rem );
  -webkit-backdrop-filter: blur( 0.25rem );
  border-radius: 0.75rem;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  margin: 1rem;
  margin-bottom: 3rem;
}

.forum-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.forum-heading > .user-details {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  font-size: var(--size-06);
  color: var(--swatch-06);
}

.forum-heading .profile-pic {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
}

.profile-pic {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  margin-right: 1rem;
}

.forum-nav {
  display: flex;
  justify-content:space-between;
  align-items: center;
}


/* IDEA: Make a nav component while refactoring across all services */
.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  height: 3.0rem;

  background-color: var(--swatch-04);
  z-index: 2; /* You may need to adjust this value based on other elements on your page */

  width: 100vw;
}

.nav.page_info {
    justify-content: end;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.nav.page_info > div {
    padding: 0.25rem;
}

.fa-button,
.fa-button a {
  cursor: pointer;
  user-select: none;
  padding: 1.7em;
  height: 1.5rem;
  color: var(--swatch-07);
  border: none;
  transition: all 0.3s ease; /* Smooth hover effect */
}

.fa-button_current,
.fa-button_current a {
    
  padding: 1.7em;
  color: var(--swatch-03);
}

.fa-button:hover,
.fa-button a:hover {
  color: var(--swatch-08);
}

.fa-button_current:hover,
.fa-button_current a:hover {
  color: var(--swatch-06);
}

.fa-button > .svg-inline--fa {
  color: var(--swatch-10);
}

.chat-modal {
  background: linear-gradient(to bottom, var(--swatch-10), var(--swatch-08));
  padding: 0rem;
  inset: 1rem;
  border-radius: 1rem;
  position: absolute;
  outline: 0;
  border: 0;
}

.menu-area > .chip-container > h6 {
  padding-left: 0.5rem;
}

.chat_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.chat_content > .rules_container {
  overflow-y: auto;
  flex-grow: 1;
  overflow-wrap: anywhere;
  width: 100%;
}

.rules_container > h5, 
.rules_container > h4 {
  margin: 1rem
}

.chat_close {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem 2rem 1rem 1rem
}

.chat_close:hover {
  cursor: pointer;
  color: var(--swatch-06);
}

.create-forum {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
    .forum-fixed_wrapper {
        height: 69vh;
        overflow-y: auto;
    }
  }

