/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
#forum-page {
  max-width: 33rem;
  margin: 0 auto;
}

.revibe_actions {
  padding: 0.75rem;
  display: flex;
  justify-content: center !important;

  color: var(--swatch-10);
  background: -moz-linear-gradient(top, var(--swatch-05) 0%, var(--swatch-02) 80%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, var(--swatch-05) 0%, var(--swatch-02) 80%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, var(--swatch-05) 0%, var(--swatch-02) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.revibe_actions > div {
  margin: 0rem 1.5rem;
}

.revibe_actions > div:hover {
  cursor: pointer;
  color: var(--swatch-09);
}

.main_input {
  width: calc(100vw - 4rem);
  padding: 3rem;
  margin: 0.5rem;
  border-radius: 1rem;
}

.message {
  padding: 1rem;
  margin: 1rem;
  color: var(--swatch-04);
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 0.5rem 2rem 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 0.25rem );
  -webkit-backdrop-filter: blur( 0.25rem );
  border-radius: 0.75rem;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.message:hover {
  background-color: var(--swatch-12);
  cursor: pointer;
}

.date {
  margin-top: 2rem;
}

.read-more {
    color: var(--swatch-07);
    font-weight: var(--weight-03);
}

.replies-toggle {
  font-weight: var(--weight-03);
}

.grid {
  display: flex;

  flex-direction: column;  
}

.grid-button {
  display: flex;

  flex-direction: column;

  align-items: center;

  width: 6rem;

  height: 6rem;

  justify-content: center;

  font-size: var(--size-02);

  padding: 0.5rem;

  margin: 0.5rem;

  border: solid 1px var(--swatch-07);

  border-radius: 1rem;
}

.grid-button > h6 {
  margin: 0;

  font-weight: var(--weight-03);
}

.row {
  display: flex;
  
  justify-content: space-around;
   
}

.chat-modal {
  background: linear-gradient(to bottom, var(--swatch-10), var(--swatch-08));
  padding: 0.5rem;
    inset: 1rem;
    border-radius: 1rem;
    position: absolute;
    outline: 0;
    border: 0;
}

.chat-modal-info_area {
  display: flex;
  flex-direction: column;
  
  /* border: solid rgb(18, 225, 28) 1rem; */
  flex-grow: 1;
}

.chat-modal-info_area-heading {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.chat-modal-info_area-heading > h4 {
  margin: 0;
}

.chat-modal-info_area-heading > h4 > svg {
  margin-right: 0.5rem;
}

.chat-modal-info_area-body .message-content {
  max-height: 8rem;
  overflow: auto;
}

.chat-modal-info_area-filler_text {
  display: flex;
  justify-content: center;
  align-items: center;
  
  flex-grow: 1;

  margin: 0;
  font-weight: var(--weight-03);
}

.button > svg {
  margin-right: 1rem;
}

.updateButton {
  background-color: var(--swatch-04);
  color: var(--swatch-10);
}

.forum-nav {
  display: flex;
  justify-content:space-between;
  align-items: center;
  min-height: 3rem;
}

.modal-back, 
.magnify-right {
  display:flex;
  justify-content: right;
}

.magnify-right > svg {
  margin-right: 0.25rem;
}

.forum-magnify {
  display: flex;
  align-items: center;
}

.quick-comment-actions > div > svg:hover,
.forum-magnify:hover {
  cursor: pointer;
  color: var(--swatch-06);
}

.message-details {
  display: flex;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

/* LAYOUT */

.forum-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.forum-details {
  margin: 0;
  padding: 1rem;
  color: var(--swatch-04);
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 0.5rem 2rem 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 0.25rem );
  -webkit-backdrop-filter: blur( 0.25rem );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  
  /* Uncomment to see the boxes more easily */
  /* border: 1rem solid red; */
  
  z-index: 1;
}

.forum-details > .share_buttons {
  width: calc(100% - 1.5rem);
  margin-top: 0;
}

.forum-comments {
  padding-top: 1rem;
  flex-grow: 1;
  overflow-y: auto;
  overflow-wrap: anywhere;
  
  /* Uncomment to see the boxes more easily */
  /* border: 1rem solid green; */
}

.forum-comments > .copied-message {
  z-index: 1;
}

.forum-comments > .copied-message > .error-message {
  border-radius: 0;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quick-comment {
  background-color: var(--swatch-09);
  border-radius: 0 0 0.75rem 0.75rem;
}

.quick-comment-title {
  color: var(--swatch-04);
  font-weight: var(--weight-03);
}

.quick-comment-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  /* border:solid rgb(255, 225, 0) 1rem; */
}

.search_input-container {
  flex-grow: 1;
  margin: 0 1rem; /* Adjust as needed */

  display: flex;
}

.search_input-container textarea {
  resize: none;
  overflow-y: auto;
  
  max-height: 2rem;

  width: 100%; /* Add this line */
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.75rem; /* Add this line */

  outline: none; /* Add this line */
  border: none; /* Add this line */
}

textarea::-webkit-scrollbar {
  border-radius: 0 0.75rem 0.75rem 0;
}

textarea::-webkit-scrollbar-thumb {
  outline: 0px solid var(--swatch-04);
  background-color: var(--swatch-04);

  border-radius: 0 0.75rem 0.75rem 0;
}

textarea::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track-piece {
  border-radius: 0.75rem;
}

.good-vibes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border: solid var(--swatch-05) 0.08rem;
  border-radius: 15px;
  /* border: solid purple 1rem; */
  height: 1rem;
  width: fit-content;
}

.good-vibes-group {
  display: flex;
  width: max-content;
}

.good-vibes-group-container {
  width: 100%;
  overflow-y: hidden;
}

.greener {
  display: flex;
  flex-direction: column;
  /* border: solid rgb(12, 59, 15) 1rem; */
  height: 100%;
}

/* IDEA: Refactor connected event group into reusable component */
.gradient-border {
  background: -moz-linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border: 0.0625rem outset var(--swatch-04);
}

.connected-event_group {
  background: -moz-linear-gradient(to top right, var(--swatch-12) 10%, var(--swatch-09) 90%) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(to top right, var(--swatch-12) 10%, var(--swatch-09) 90%) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top right, var(--swatch-12) 10%, var(--swatch-09) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.connected_vibe_label {
  margin: 0.25rem 0.5rem ;
  background: -moz-linear-gradient(to top right, var(--swatch-10) 10%, var(--swatch-09) 90%) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(to top right, var(--swatch-10) 10%, var(--swatch-09) 90%) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top right, var(--swatch-10) 10%, var(--swatch-09) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  width: fit-content;

  color: var(--swatch-04);

  font-weight: var(--weight-03);
  box-shadow: 0 0.0625rem 0.25rem 0 rgba( 31, 38, 135, 0.37 );
  padding: 0.0625rem 0.5rem;
  border-radius: 0.5rem;

  border: 0.0625rem outset var(--swatch-08);

  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModal__Overlay {
  z-index: 2;
}

@media (min-width: 33rem) {
  #forum-page {
    border-left: 0.0625rem inset var(--swatch-08);
    border-right: 0.0625rem inset var(--swatch-08);
  }

  .chat-modal, .greener {
    margin: 0 auto;
    max-width: 33rem;
  }
}
