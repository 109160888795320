/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.error-message {
    position: relative;
    margin: 2rem 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 0.125rem solid var(--swatch-11);
    font-weight: var(--weight-03);
    border-radius: 0.5rem;
    text-align: center;
    overflow: hidden;
  
    background: -moz-linear-gradient(to bottom right, var(--swatch-03) 0%, var(--swatch-06) 80%); /* FF3.6-15 */
    background: -webkit-linear-gradient(to bottom right, var(--swatch-03) 0%, var(--swatch-06) 80%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom right, var(--swatch-03) 0%, var(--swatch-06) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
  
    color: var(--swatch-11);
  }
  
  .light {
    position: absolute;
    width: 0px;
    opacity: .75;
    background-color: var(--swatch-11);
    box-shadow: var(--swatch-11) 0px 0px 20px 2px;
    opacity: 0;
    top: 100vh;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
  }
  
  
  .x1{
  -webkit-animation: floatUp 4s infinite linear;
  -moz-animation: floatUp 4s infinite linear;
  -o-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
   -webkit-transform: scale(1.0);
   -moz-transform: scale(1.0);
   -o-transform: scale(1.0);
  transform: scale(1.0);
  }
  
  .x2{
  -webkit-animation: floatUp 7s infinite linear;
  -moz-animation: floatUp 7s infinite linear;
  -o-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
  }
  
  .x3{
  -webkit-animation: floatUp 2.5s infinite linear;
  -moz-animation: floatUp 2.5s infinite linear;
  -o-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  -o-transform: scale(.5);
  transform: scale(.5);
  left: -15%;
  }
  
  .x4{
    -webkit-animation: floatUp 4.5s infinite linear;
    -moz-animation: floatUp 4.5s infinite linear;
    -o-animation: floatUp 4.5s infinite linear;
    animation: floatUp 4.5s infinite linear;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    left: -34%;
  }
  
  .x5{
    -webkit-animation: floatUp 8s infinite linear;
    -moz-animation: floatUp 8s infinite linear;
    -o-animation: floatUp 8s infinite linear;
    animation: floatUp 8s infinite linear;
    -webkit-transform: scale(2.2);
    -moz-transform: scale(2.2);
    -o-transform: scale(2.2);
    transform: scale(2.2);
    left: -57%;
  }
  
  .x6{
    -webkit-animation: floatUp 3s infinite linear;
    -moz-animation: floatUp 3s infinite linear;
    -o-animation: floatUp 3s infinite linear;
    animation: floatUp 3s infinite linear;
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8);
    left: -81%;
  }
  
  .x7{
    -webkit-animation: floatUp 5.3s infinite linear;
    -moz-animation: floatUp 5.3s infinite linear;
    -o-animation: floatUp 5.3s infinite linear;
    animation: floatUp 5.3s infinite linear;
    -webkit-transform: scale(3.2);
    -moz-transform: scale(3.2);
    -o-transform: scale(3.2);
    transform: scale(3.2);
    left: 37%;
  }
  
  .x8{
    -webkit-animation: floatUp 4.7s infinite linear;
    -moz-animation: floatUp 4.7s infinite linear;
    -o-animation: floatUp 4.7s infinite linear;
    animation: floatUp 4.7s infinite linear;
    -webkit-transform: scale(1.7);
    -moz-transform: scale(1.7);
    -o-transform: scale(1.7);
    transform: scale(1.7);
    left: 62%;
  }
  
  .x9{
    -webkit-animation: floatUp 4.1s infinite linear;
    -moz-animation: floatUp 4.1s infinite linear;
    -o-animation: floatUp 4.1s infinite linear;
    animation: floatUp 4.1s infinite linear;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    left: 85%;
  }
  
  @-webkit-keyframes floatUp{
    0%{top: 100vh; opacity: 0;}
    25%{opacity: 1;}
    50%{top: 0vh; opacity: .8;}
    75%{opacity: 1;}
    100%{top: -100vh; opacity: 0;}
  }
  @-moz-keyframes floatUp{
    0%{top: 100vh; opacity: 0;}
    25%{opacity: 1;}
    50%{top: 0vh; opacity: .8;}
    75%{opacity: 1;}
    100%{top: -100vh; opacity: 0;}
  }
  @-o-keyframes floatUp{
    0%{top: 100vh; opacity: 0;}
    25%{opacity: 1;}
    50%{top: 0vh; opacity: .8;}
    75%{opacity: 1;}
    100%{top: -100vh; opacity: 0;}
  }
  @keyframes floatUp{
    0%{top: 100vh; opacity: 0;}
    25%{opacity: 1;}
    50%{top: 0vh; opacity: .8;}
    75%{opacity: 1;}
    100%{top: -100vh; opacity: 0;}
  }
  