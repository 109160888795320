/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
/* ChipScroll.css */
.chip-container {
  font-family: Arial, sans-serif;
}

.chip-container > h6 {
  margin: 0;
  font-weight: var(--weight-03);
}

.chip-scroll {
  user-select: none;
  display: flex;
  overflow-x: auto;
  gap: 0.5rem;
  padding: 0.5rem;
  padding-bottom: 1rem;
  white-space: nowrap;
}

.chip {
  background: var(--swatch-10);
  border-radius: 16px;
  padding: 0.5rem 1rem;
  user-select: none;
  box-shadow: 0 0.125rem 0.5rem var(--swatch-02);
  font-size: 1rem; /* Use rems for scalability */
}

.chip:hover {
  background-color: var(--swatch-11);
}

.chip-scroll::-webkit-scrollbar {
  border-radius: 0 0.75rem 0.75rem 0;
  height: 0.125rem;
  background-color: var(--swatch-07);
}

.chip-scroll::-webkit-scrollbar-thumb {
  outline: 0px solid var(--swatch-04);
  background-color: var(--swatch-09);
}

.chip-scroll::-webkit-scrollbar-track,
.chip-scroll::-webkit-scrollbar-track-piece {
  border-radius: 0.75rem;
}
