/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.submit {
    background: linear-gradient(to bottom, rgb(120, 237, 229), rgb(203, 254, 255));
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 3rem;    
    padding-left: 3rem; 
    color: black;
}
  

.comment{color:darkgreen}

.container {
    resize: horizontal;
    overflow: auto;
    width: 60%;
    min-width: 100px;
    margin: 0px auto;
    border: 1px solid black;
    padding: 20px;
  }
  
  .ruler {
    margin: 0 auto;
    background-color: rgb(255, 255, 223);
    height: 20px;
    width: 80%;
    display: flex;
    justify-content: center;
  }
  
  .ruler-cell {
    position: relative;
    height: 100%;
    width: calc(100% - 20px);
    border-left: 1px solid black;
    border-right: 1px solid black;
    background-repeat: no-repeat;
    background-size: 100% 1px, 1px 8px, 1px 8px, 1px 8px, 1px 8px;
    background-position: 0% center, 20% center, 40% center, 60% center, 80% center;
    background-image: linear-gradient(gray, gray), linear-gradient(gray, gray),
      linear-gradient(gray, gray), linear-gradient(gray, gray),
      linear-gradient(gray, gray);
  }
  
  .ruler-cell span {
    position: absolute;
    top: -15px;
    font-size: 12px;
    text-align: center;
  }
  
  .zero {
    left: 0;
    transform: translateX(-50%);
    display: none;
  }
  
  .number {
    right: 0px;
    transform: translateX(50%);
  }
  
  .ruler-cell:first-child .zero{
  display:block;
}

.question {
    margin-bottom: 5rem; /* Add margin-bottom to create space between questions */
  }
  
  .quiz-container {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .marker {
    position: absolute;
    height: 20px;
    width: 2px;
    background-color: red; /* Change color as needed */
    top: -10px;
  }